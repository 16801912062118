import React, { useState } from 'react'
// import { Link } from "react-router-dom";
import Recaptcha from 'react-recaptcha'

/* packages */
import { Inputs, Buttons } from '../../lib-atomic'
import { FormHook } from '../../lib-atomic-functions'
import {
  ModXlWrapp,
  // ModPageTitleWrapp,
  // ModTitleText,
  // ModContentText,
  ModFormLabelText,
} from '../../lib-atomic-design-section'
// import { FetchContextApi } from "../../lib-atomic-functions";
/* packages */

/* stores */
// import { AuthLogin } from "../../app-module-admin-container/stores";
/* stores */

import CONFIG from '../config'

/* CONSTANTS */
const LOGINOBJECTS = CONFIG.application.account.objects
// const ENDPOINTS = CONFIG.application.account.api.endpoints;
// const LOGINOBJECTSCONFIG = {
//   url: ENDPOINTS.auth.url,
//   method: ENDPOINTS.auth.method,
//   headers: ENDPOINTS.auth.headers,
//   responseType: "json"
// };
// const LOGINFAILRESPONSE = CONFIG.application.account.responses.fail;
// const UNVERIFIEDRESPONSE = CONFIG.application.account.responses.unverified;
/* CONSTANTS */

const login = props => {
  // const { token } = LocalStorage.getLS().login;
  /* state */
  const [, setLoaded] = useState(false)
  const [verified, setVerified] = useState(false)

  // console.log(AuthLogin);
  /* state */
  // const [accountLoginContext, accountLoginMethod] = FetchContextApi(
  //   LOGINOBJECTSCONFIG,
  //   AuthLogin
  // );

  /* state */

  // useEffect(()=> {
  //   DETAILOBJECTSCONFIG.headers = { ...DETAILOBJECTSCONFIG.headers, Authorization: token}
  // }, [token]);
  const callback = () => {
    console.log('loaded recaptcha...')
  }

  // specifying verify callback function
  const verifyCallback = response => {
    if (response) {
      setVerified(true)
      setLoaded(true)
    }
  }

  const handleLogin = async () => {
    setLoaded(true)
    if (verified) {
      // await accountLoginMethod({ body: JSON.stringify(values), query: false });
      // LocalStorage.addLS("login", {
      //   ...accountLoginContext.data.json,
      //   username: values.username
      // });
      // if (accountLoginContext.data.invalidToken) return false;
      // props.history.push("/u/login-logout-redirect");
    }
  }

  const { values, onChange, onSubmit, error } = FormHook.useFormHook(
    handleLogin,
    LOGINOBJECTS
  )

  const password = {
    ...LOGINOBJECTS.password,
    error: error.password,
    value: values.password,
    onChange,
  }
  const confirmPassword = {
    ...LOGINOBJECTS.email,
    error: error.email,
    value: values.email,
    onChange,
  }
  const submitButton = { fullWidth: true }

  return (
    <form onSubmit={onSubmit} noValidate>
      <ModXlWrapp>
        <ModFormLabelText>New Password</ModFormLabelText>
        <Inputs.Text {...password} />
      </ModXlWrapp>
      <br />
      <ModXlWrapp>
        <ModFormLabelText>Confirm Password</ModFormLabelText>
        <Inputs.Text {...confirmPassword} />
      </ModXlWrapp>
      <br />
      <Recaptcha
        sitekey="6Le-u7UUAAAAAFtIWVsYzu8cpqQAY1sVwdT0vDyE"
        render="explicit"
        verifyCallback={verifyCallback}
        onloadCallback={callback}
      />
      <br />
      <ModXlWrapp>
        <Buttons.Normal {...submitButton}>Update my password</Buttons.Normal>
      </ModXlWrapp>
    </form>
  )
}

export default login

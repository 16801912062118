import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'bulk ordering container'
const server = null
const service = null
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  routes: {
    label: 'Sections',
    prefix: '/s/:channel/:module',
  },
  setup,
}

export default config

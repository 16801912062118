import setupurllive from './setup-url-live'
import setupurluat from './setup-url-uat'

// let setup = setupurllive
let setup = setupurluat

// ** Will use setupurllive when buiulding for production **
if (process.env.REACT_APP_BUILD_ENV === 'production') setup = setupurllive

console.log(process.env.REACT_APP_BUILD_ENV, ' process.env.REACT_APP_BUILD_ENV')

export default {
  project: 'Subway Rewards',
  name: 'Subway Rewards',
  url: '',
  auth_landing_module: '/ordering',
  idle_limit: 1800000, //180000,
  domain_prefix: 'https://subwayrewards.',
  dev_env_token: 'rceotaetvitetvsvcnekentadeo',
  default_country: 'gb',
  default_lang: 'gb',
  ...setup,
}
// COUNTRY CODES
// default_country | default_lang
// at | de
// de | de
// fi | fi
// fr | fr
// ie | gb
// nl | nl
// se | se
// gb | gb  THIS IS FOR UK

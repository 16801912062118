import React from 'react'

/* container */
import SectionContainer from '../../app-module-section-container/default'
/* container */

/* mui */
import { makeStyles } from '@material-ui/core/styles'
// import GridList from "@material-ui/core/GridList";
// import GridListTile from "@material-ui/core/GridListTile";
/* mui */

/* packages */
// import { GlobalContext } from "../../lib-atomic-context";
// import {
//   WindowSize,
//   WindowLayout,
//   WindowFont
// } from "../../lib-atomic-functions";
/* packages */

/* form */
import ChangePasswordForm from '../form/changepassword'
/* form */

/* modules */
// const CMSLayoutItem = lazy(() =>
//   import("../../app-module-cms-section-layout-item/default")
// );
/* modules */

/* constant */
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 80,
    maxWidth: 380,
    minWidth: 300,
    paddingLeft: 10,
    paddingRight: 10,
    margin: 'auto',
  },
  gridList: {
    width: 800,
  },
}))
/* constant */

const index = props => {
  const classes = useStyles()

  // /* state */
  // const { boxes, setup, contents } = useContext(GlobalContext);
  // /* state */

  // const { width, height } = WindowSize();
  // const {
  //   layoutWidth,
  //   layoutHeight,
  //   layoutCols,
  //   getColsWidth,
  //   getColsHeight
  // } = WindowLayout(width, height);
  // const { getFontSizes } = WindowFont();

  // const { masterboxes } = boxes.state.data;
  // const { mastersetup } = setup.state.data;
  // const { mastercontents } = contents.state.data;
  // console.log(mastercontents);
  return (
    <SectionContainer {...props}>
      <div className={classes.root}>
        <ChangePasswordForm {...props} />
      </div>
    </SectionContainer>
  )
}

export default index

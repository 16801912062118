import React, { useEffect, useContext } from 'react'

import { GlobalContext } from '../../lib-atomic-context'

/* module */
import Spinner2 from '../../app-module-cms-section-page-spinner/default'
/* module */

import CONFIG from '../config'

const languageFromSetup = CONFIG.setup.setup.languages

const index = (props) => {
  return props.children
}

export default index

import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'cms'
const server = null
const service = null
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    account: {
      api: {
        endpoints: {
          list: {
            headers: {
              'Content-Type': 'application/json',
              Authorization: null,
            },
            doc: 'https://tr4ns10.tr4ns.com/Westfield-Reporting',
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.tranxactor.url[environment] + '/configs',
            method: 'GET',
          },
          auth: {
            headers: {
              'Content-Type': 'application/json',
              Authorization: null,
            },
            doc: 'http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/auth/token',
            url: setup.setup.api.tranxactor.url[environment] + '/configs',
            method: 'POST',
          },
        },
      },
      objects: {
        password: {
          name: 'password',
          initial: '',
          placeholder: 'Password',
          helperText: false,
          rules: ['isNotNull'],
        },
        confirmPassword: {
          name: 'confirmPassword',
          initial: '',
          placeholder: 'Confirm Password',
          helperText: false,
          rules: ['isNotNull'],
        },
      },
      responses: {
        fail: 'Incorrect email or password',
        unverified: 'Please verify your account by ticking the recaptcha.',
      },
    },
  },
  routes: {
    label: 'Forgot Password',
    prefix: '/s/web/forgot-password',
  },
  setup,
}

export default config

import React, { useState, useContext } from 'react'
import { Wheel } from "./components/Roulette";
import { DisplayMessageImg, RotateDisplayPrize } from "./components/styles.js";
import ErrorOverlay from './components/ErrorOverlay';
import { GlobalContext } from '../../lib-atomic-context'
import { BackgroundSettings, BackgroundFr } from "./components/styles.js";
import './styles.css';  

const index = (props) => {
  const [hasClicked, setHasClicked] = useState(false);
  const [hasClickedStop, setHasClickedStop] = useState(false);
  const [prizeNum, setPrizeNum] = useState();
  const [prizeNumDebug, setPrizeNumDebug] = useState();
  const [noWin, setNoWin] = useState();
  const [mustSpin, setMustSpin] = useState(false);
  const [open, setOpen] = useState(false);
  const [resetCheck, setResetCheck] = useState(false);
  const [spinRemaining, setSpinRemaining] = useState();
  const {spinthewheel} = useContext(GlobalContext)
  const wheelcontent = spinthewheel.state.data.spinthewheelimages[0];

  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const resetWheel = () => {
    setResetCheck(true)
    setTimeout(() => {
      setResetCheck(false)
      setMustSpin(true);
      setHasClickedStop(false);
      setHasClicked(true);
      setPrizeNum()
      setOpen(false);
      onClick();
    }, 450);
  }

  // Iterate through Prize Reveal Images
  function toObject(arr) {
    var rv = {};
    for (var i = 1; i < arr.length+1; ++i)
      rv[i] = arr[i-1].url;
    return rv;
  }

  const frPrizes = toObject(wheelcontent.Reveal_images)

  // Get prize length from prizes object
  const prizeLength = Object.keys(frPrizes);

  // // FOR DEVELOPMENT - MOCK API CALL
  // const onClick = () => {
  //   const randomPrize = getRandomInt(1, 8);
  //   // const randomPrize = 2;
  //   if (!hasClicked) {
  //     setMustSpin(true);
  //   }
  //   setTimeout(() => {
  //       if(prizeNumDebug){
  //         console.log("PRIZE NUM DEBUG: ",prizeNumDebug * 1);
  //         setPrizeNum(prizeNumDebug * 1);
  //       } else {
  //         console.log("RANDOM PRIZE RESULT: ",randomPrize);
  //         setPrizeNum(randomPrize);
  //       }
  //       setNoWin(4);
  //     setHasClickedStop(true);
  //   }, 2000);
  //   setHasClicked(true);
  // };

  // FOR PRODUCTION - LIVE API CALL
    const onClick = async ()  => {
        try{
          await fetch(props.useApi + props.gameToken, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            }
          })
          .then(response => response.json())
          .then(data =>{
            console.log(data);
            setMustSpin(true);
            setSpinRemaining(data.spinLeft);
            // Adjust prizeId based on PrizeID from backend
            // E.g. If FR prize wheel has 6 prizes and Backend ID Prizes start from 37-43
            // We need to subtract 36 so prizes will be in range of 1-6.
            // setNoWin sets the prize no to hide/show certain elements of the prize reveal
            setTimeout(() => {
              if(wheelcontent.mastercountries.abbr === "fr"){
                setPrizeNum(data.prizes[0].prizeId - 160);
                setNoWin(6);
              } else if (wheelcontent.mastercountries.abbr === "nl") {
                setPrizeNum(data.prizes[0].prizeId - 120);
                setNoWin(4);
              } else if (wheelcontent.mastercountries.abbr === "gb") {
                setPrizeNum(data.prizes[0].prizeId - 55);
                setNoWin(6);
              } else if (wheelcontent.mastercountries.abbr === "ie") {
                setPrizeNum(data.prizes[0].prizeId - 65);
                setNoWin(6);
              } 
              else {
                setPrizeNum(data.prizes[0].prizeId);
                setNoWin(5);
              }
              setHasClickedStop(true);
              console.log("PRIZE WON", data.prizes[0].prizeId);
            }, 3000);
          });
        } catch (error) {
          console.error(error);
        }
        setHasClicked(true);
    };

  return (
  <div className='spincontainer'>
    <ErrorOverlay 
    outcomeCode={props.tokenRes.outcomeCode} 
    errPromoend={wheelcontent.error_promotionended} 
    errSpun={wheelcontent.error_alreadyspun} 
    />  
    <BackgroundSettings bgImage={wheelcontent.Background_image.url}>
    {/* <BackgroundSettings> */}
        <div className="wheelContainer">
          <div className={`mealDealArrow mealDealArrow${wheelcontent.mastercountries.abbr}`} >
            <img style={{ width:"100%", zIndex:"3" }} className={`${wheelcontent.mastercountries.abbr === 'fr' ? 'addMarginTop' : 'addCustomMargin'}`} src={wheelcontent.other_image.url} alt="Meal Deal Banner" />
          </div>
          { 
            wheelcontent.mastercountries.abbr === 'nl' ?
            <img
            src="/assets/stormborn/spinthewheel/nlPoint.png"
            alt="marker"
            className="wheelPointernl"
            />
            :
            wheelcontent.mastercountries.abbr === 'fr' ?
            <img
            src="/assets/stormborn/spinthewheel/frPointer.png"
            alt="marker"
            className="wheelPointerfr"
            />
            :
            <img
            src="/assets/stormborn/spinthewheel/SpinnerPointer.png"
            alt="marker"
            className="wheelPointer"
            />
          }
          <BackgroundFr bgImage="/assets/stormborn/spinthewheel/frBgWheel.png">

          <div style={{ overflow:"hidden", zIndex:"1", position:"relative", paddingTop:"2rem"}}>
            <Wheel
              wheelImage={wheelcontent.wheel_image.url}
              country={wheelcontent.mastercountries.abbr}
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNum}
              prizeTotal = {prizeLength.length} // Set prizeTotal based on no of prizes in strapi
              onClickingStop={hasClickedStop}
              onStopSpinning={() => {
                setMustSpin(false);
                handleOpen();
              }}
            />
            <div className={`spinButton`}>
              <button onClick={() => onClick()} style={{ background: "none", border: "none"}} disabled={hasClicked}>
                <img  
                  src={wheelcontent.spinbutton_image.url}
                  alt="Spin to Win button" 
                  className={`${hasClicked ? `buttonStyle zoomout` : "buttonStyle" }`} 
                />
              </button>
            </div>
          </div>
          </BackgroundFr>
          <div className={`${wheelcontent.mastercountries.abbr === 'fr' ? 'hidden' : 'prizetowin'}`}>
            <img style={{ width:"100%", maxWidth:"275px" }} src={wheelcontent.text_image.url} alt="" />
          </div>
        </div>
        {/* T&C Message */}
        {/* <div className='tandcwheel' dangerouslySetInnerHTML={{__html: wheelcontent.tandc_text}} /> */}
        {/* Winning display message */}
        <div className={`${wheelcontent.mastercountries.abbr === "fr" ? "displayMessageBottle" :"displayMessage"} ${open ? "placeinfront" : ""}`}>
        <div className={`${resetCheck ? "moveup" : ""}`}>
          {wheelcontent.mastercountries.abbr === 'fr' ? 
            <RotateDisplayPrize 
            className={`${open ? "start-rotate" : "end-rotate" }`}
            src={`${frPrizes[prizeNum]}`} 
            />
            :
            <DisplayMessageImg 
              className={`${open ? "start-rotate" : "end-rotate" }`}
              src={`${frPrizes[prizeNum]}`} 
            />
          }
          
          {
            spinRemaining !== 0 && (wheelcontent.mastercountries.abbr === 'gb' || wheelcontent.mastercountries.abbr === 'ie') ? 
            <button className={`rejour-button ${open ? 'rejour-anim' : '' }`} onClick={()=> resetWheel()}>SPIN AGAIN</button> 
            : ""
          }
        </div>
        {
        // RETRY BUTTON FOR FR
        /* {
          prizeNum === noWin && wheelcontent.mastercountries.abbr === 'fr' ? 
          <button className='rejour-button' onClick={()=> resetWheel()}>REJOUR</button> 
          : ""
        } */
        }
        {/* {wheelcontent.mastercountries.abbr === 'fr' ? '' : prizeNum === noWin ?
        "" :
        <p className={`displayMessageEmail ${open ? "displayMessageEmailAnim" : ""}`}>
          {wheelcontent.display_message}
        </p> 
        } */}
      </div>

        {/* DEBUG UI */}
        {/* {process.env.REACT_APP_BUILD_ENV === 'production' ? "" : 
          <div className='debugOption'>
            <p style={{marginBottom:"0", fontSize:"0.8rem", fontWeight:"bold" }}>TEST MENU - Set Prize No.</p>
            <p style={{marginBottom:"0.2rem", fontSize:"0.8rem" }}>Max = 8</p>
            <input min={1} max={8} maxLength={1} placeholder='Leave empty to randomize' type='number' 
              onChange={(e)=>setPrizeNumDebug(e.target.value)} 
              value={prizeNumDebug} 
            />
            <button onClick={()=>resetWheel()}>Start Spin (Again)</button>
          </div>
        } */}
    </BackgroundSettings>
  </div>
  );
}

export default index

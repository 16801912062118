// public
import home from '../../../../app-module-home-container'
import login from '../../../../app-module-login-container'
import activation from '../../../../app-module-activation-container'
import verification from '../../../../app-module-verification-container'
import register from '../../../../app-module-register-container'
import forgot_password from '../../../../app-module-forgot-password-container'
import reset_password from '../../../../app-module-reset-password-container'
import faq from '../../../../app-module-faq-container'
import subsquadfaq from '../../../../app-module-subsquadfaq-container'
import contactus from '../../../../app-module-contactus-container'
import terms from '../../../../app-module-terms-container'
import privacynotice from '../../../../app-module-privacynotice-container'
import cookies from '../../../../app-module-cookies-container'
import logout from '../../../../app-module-logout-container'
import impressum from '../../../../app-module-impressum-container'
import subsquad from '../../../../app-module-subsquad-container'
import subrewards from '../../../../app-module-subrewards-container'
import unsubscribe from '../../../../app-module-unsubscribe-container'

// // private
import promotions from '../../../../app-module-promotions-container'
// import posthome from '../../../../app-module-post-home-container'
import iou from '../../../../app-module-iou-container'
// import change_password from '../../../../app-module-change-password-container'
// import news from '../../../../app-module-news-container'
// import offers from '../../../../app-module-offers-container'
// import rewards from '../../../../app-module-rewards-container'
import history from '../../../../app-module-my-history-container'
import dashboard from '../../../../app-module-dashboard-container'
import points from '../../../../app-module-my-points-container'
import profile from '../../../../app-module-my-profile-container'
import preferences from '../../../../app-module-my-preferences-container'
import lostcard from '../../../../app-module-lostcard-container'
import update from '../../../../app-module-update-container'
import transfercard from '../../../../app-module-transfercard-container'

import campaign from '../../../../app-module-campaign-container'

const Modules = {
  // public //
  home,
  login,
  activation,
  verification,
  register,
  forgot_password,
  reset_password,
  logout,
  impressum,
  faq,
  subsquadfaq,
  contactus,
  terms,
  privacynotice,
  cookies,
  subsquad,
  subrewards,
  unsubscribe,
  // change_password, not used atm

  // private //
  iou,
  // posthome,
  // news,
  // offers,
  // rewards,
  history,
  dashboard,
  points,
  profile,
  preferences,
  lostcard,
  promotions,
  update,
  transfercard,
  campaign,
}
export default Modules

// ------------------------
// public
// ------------------------
// ------------------------
/* home module */
import Home from '../../../../app-module-home-page-landing'
/* login module */
import Login from '../../../../app-module-login-page-landing'
/* account activation module */
import Activation from '../../../../app-module-activation-page-landing'
import ActivationPopup from '../../../../app-module-activation-page-submit-popup'
import ActivationResend from '../../../../app-module-activation-page-resend'
import ActivationResendPopup from '../../../../app-module-activation-page-resend-submit-popup'
/* account verification module */
import Verification from '../../../../app-module-verification-page-landing'
import VerificationPopup from '../../../../app-module-verification-page-submit-popup'
import VerificationResend from '../../../../app-module-verification-page-resend'
import VerificationResendPopup from '../../../../app-module-verification-page-resend-submit-popup'
/* register module */
import Register from '../../../../app-module-register-page-landing'
/* forgot password module */
import ForgotPassword from '../../../../app-module-forgot-password-page-landing'
import ForgotPasswordSubmit from '../../../../app-module-forgot-password-page-submit-popup'
/* reset password module */
import ResetPassword from '../../../../app-module-reset-password-page-landing'
import ResetPasswordPopup from '../../../../app-module-reset-password-page-submit-popup'
import FAQ from '../../../../app-module-faq-page-landing'
import SubsquadFaq from '../../../../app-module-subsquadfaq-page-landing'
import ContactUs from '../../../../app-module-contactus-page-landing'
import Terms from '../../../../app-module-terms-page-landing'
import PrivacyNotice from '../../../../app-module-privacynotice-page-landing'
import Cookies from '../../../../app-module-cookies-page-landing'
import Logout from '../../../../app-module-logout-page-landing'
/* impressum */
import ImpressumLanding from '../../../../app-module-impressum-page-landing'
import Subsquad from '../../../../app-module-subsquad-page-landing'
import SubRewards from '../../../../app-module-subrewards-page-landing'
import Unsubscribe from '../../../../app-module-unsubscribe-page-landing'
import UnsubscribeSuccess from '../../../../app-module-unsubscribe-page-success'
import UnsubscribeFail from '../../../../app-module-unsubscribe-page-fail'

// ------------------------
// private
// ------------------------
// ------------------------

import Promotions from '../../../../app-module-promotions-page-landing'
/* iou module */
import Iou from '../../../../app-module-iou-page-landing'
import IouPopup from '../../../../app-module-iou-page-submit-popup'
/* change password module */
// import ChangePassword from '../../../../app-module-change-password-page-landing'
/* history module */
import History from '../../../../app-module-my-history-page-landing'
import HistoryDetail from '../../../../app-module-my-history-page-detail'
import DashboardLanding from '../../../../app-module-dashboard-page-landing'
import Points from '../../../../app-module-my-points-page-landing'
import Profile from '../../../../app-module-my-profile-page-landing'
import Preferences from '../../../../app-module-my-preferences-page-landing'
import LostCard from '../../../../app-module-lostcard-page-landing'
import Update from '../../../../app-module-update-page-landing'
import TransferCard from '../../../../app-module-transfercard-page-landing'

/* campaign */
import Campaign from '../../../../app-module-campaign-landing'
import CampaignSubmit from '../../../../app-module-campaign-submit-landing'

/* home */
const homeModules = [Home]

/* login */
const loginModules = [Login]

/* activation */
const activationModules = [
  Activation,
  ActivationPopup,
  ActivationResend,
  ActivationResendPopup,
]

/* verification */
const verificationModules = [
  Verification,
  VerificationPopup,
  VerificationResend,
  VerificationResendPopup,
]

const registerModules = [Register]

const iouModules = [Iou, IouPopup]
/* forgot password */
const forgotPasswordModules = [ForgotPassword, ForgotPasswordSubmit]

/* reset password */
const resetPasswordModules = [ResetPassword, ResetPasswordPopup]

/* change password */
// const changePasswordModules = [ChangePassword]

const subsquadModules = [Subsquad]
const subRewardsModules = [SubRewards]

/* history */
const historyModules = [History, HistoryDetail]

const updateModule = [Update]

const dashboardModules = [DashboardLanding]
const pointsModules = [Points]
const profileModules = [Profile]
const preferencesModules = [Preferences]
const lostcardModules = [LostCard]
const faqModules = [FAQ]
const squsuadfaqModules = [SubsquadFaq]
const contactUsModules = [ContactUs]
const termsModules = [Terms]
const privacyNoticeModules = [PrivacyNotice]
const cookiesModules = [Cookies]
const promotionsModules = [Promotions]
const logoutModules = [Logout]
const transferCardModule = [TransferCard]

/* impressum */
const impressumModules = [ImpressumLanding]

/* usubscribe */
const unsubscribeModules = [Unsubscribe, UnsubscribeSuccess, UnsubscribeFail]

/* campaign */
const campaignModules = [Campaign, CampaignSubmit]

const modules = {
  // public //
  home: homeModules,
  // posthome: postHome,
  login: loginModules,
  activation: activationModules,
  verification: verificationModules,
  register: registerModules,
  forgot_password: forgotPasswordModules,
  iou: iouModules,
  reset_password: resetPasswordModules,
  faq: faqModules,
  subsquadfaq: squsuadfaqModules,
  contactus: contactUsModules,
  terms: termsModules,
  privacynotice: privacyNoticeModules,
  cookies: cookiesModules,
  promotions: promotionsModules,
  logout: logoutModules,
  // impressum: impressumModules,
  subsquad: subsquadModules,
  subrewards: subRewardsModules,
  // private //
  // change_password: changePasswordModules, not used atm
  // news: newsModules,
  // offers: offersModules,
  // rewards: rewardsModules,
  history: historyModules,
  update: updateModule,
  dashboard: dashboardModules,
  points: pointsModules,
  profile: profileModules,
  preferences: preferencesModules,
  // lostcard: lostcardModules,
  transfercard: transferCardModule,
  unsubscribe: unsubscribeModules,
  campaign: campaignModules,
}

export default modules
